import React from 'react';
import ReactDOM from 'react-dom';

export function initReact() {
    if (window === undefined) {
        return;
    }

    window.React = window.React || React;
    window.ReactDOM = window.ReactDOM || ReactDOM;
}
