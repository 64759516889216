import React, { Component } from 'react';

import AddressSearch from '../../../../../Shared/React/js/components/addressSearch/AddressSearch';

// TODO: Consider renaming to MobilePhonesSearchAddress
export default class HomeLocationSearch extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.setTimeout(() => {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            var that = this;
            WhistleOut.getCurrentLocationViaHtml5(
                function (e) {
                    var address = e.label;
                    if (address.length > 1) {
                        that.searchInput.value = address;
                    }
                });
        }, 500);
    }

    render() {
        const searchAddressProps = {
            className: 'form-group mar-b-0',
            countryCode: this.props.countryCode,
            placeholder: this.props.text.addressSearchPlaceHolder,
            addressNotFoundMessage: null,
            commonErrorMessage: null,
            types: this.props.types,
            queryDelay: this.props.queryDelay,
            apiKey: this.props.apiKey
        };

        return (
            <React.StrictMode>
                <AddressSearch {...searchAddressProps} />
            </React.StrictMode>
        );
    }
}
